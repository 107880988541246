// import { doc } from "firebase/firestore";

const boxes = JSON.parse(localStorage.getItem("boxes")) || [];
// let boxCounter = 1;

const btnAdd = document.querySelector(".btn-submit");
const boxNumContainer = document.querySelector(".box-num");
const itemsText = document.querySelector(".textarea");
const boxesContainer = document.querySelector(".boxes__container");
const btnSearch = document.querySelector(".search-btn");
const inputSearch = document.querySelector(".search-input");
const btnDellall = document.querySelector(".dell-all");
const btnPrint = document.querySelector(".print-btn");
const userIconContainer = document.querySelector(".user-icon-container");
const userIcon = document.querySelector(".user-icon");
const userIconText = document.querySelector(".user-icon-text");
const checkbox = document.querySelector(".toggle-checkbox");

boxNumContainer.firstElementChild.textContent = boxes.at(-1)?.number + 1 || 1;

const svgEditSrc = document
  .querySelector(".box-num-edit-img")
  .getAttribute("src");

const svgEdit = `<img class="box-num-edit-img" src="${svgEditSrc}" alt="edit box title">`;

let autoNum = JSON.parse(localStorage.getItem("autoNum")) ?? true;
if (autoNum) {
  checkbox.checked = true;
} else {
  checkbox.checked = false;
}

const localsaveBoxes = function () {
  localStorage.setItem("boxes", JSON.stringify(boxes));
  localStorage.setItem("autoNum", autoNum);
};

const boxNumEditable = function (editable) {
  const btnEditNum = [...document.getElementsByClassName("box-num-edit-img")];
  if (!editable) {
    autoNum = false;
    btnEditNum.forEach((btn) => {
      btn.style.display = "flex";
    });
  } else {
    btnEditNum.forEach((btn) => {
      btn.style.display = "none";
    });
  }
  return btnEditNum;
};
boxNumEditable(autoNum);

const boxMaker = function (number, title, items) {
  return `
<article class="box" data-index="${number}">
  <div class="box-num ss02"><span>${title ? title : number}</span>
    ${svgEdit}
  </div>
  <div class="items">
    <ul class='list ss02'>
      ${items}
    </ul>
  </div>
  <div class="control">
    <button class="box__btn btn-remove">حذف</button>
    <button class="box__btn btn-edit box-edit">ویرایش</button>
  </div>
</article>
`;
};

const itemsOrganizer = function (items) {
  return items
    .split("\n")
    .filter((item) => {
      if (item.trim()) {
        return item;
      }
    })
    .map((item) => {
      if (item) return item.trim();
    });
};

const itemsHTML = (arr) => arr.map((item) => `<li>${item}</li>`).join("\n");

const boxesUpdate = function () {
  boxesContainer.innerHTML = "";
  boxes.forEach((box, i) => {
    // box.index = i + 1;
    let items = itemsHTML(box.items);
    boxesContainer.insertAdjacentHTML(
      "beforeend",
      boxMaker(box.number, box.title, items)
    );
  });
  boxNumEditable(autoNum);
  localsaveBoxes();
};
boxesUpdate();

btnAdd.addEventListener("click", function (e) {
  e.preventDefault();
  if (!itemsText.value) return;
  const title = e.target.parentElement.querySelector(".title")?.textContent;
  const itemsArr = itemsOrganizer(itemsText.value);
  const number = boxes.at(-1)?.number + 1 || 1;
  itemsText.value = "";
  boxes.push({
    number,
    title,
    items: itemsArr,
  });
  boxesUpdate();
  localsaveBoxes();
  boxNumContainer.firstElementChild.textContent = boxes.at(-1)?.number + 1 || 1;
});

// Edding made boxes
boxesContainer.addEventListener("click", function (e) {
  if (!e.target.classList.contains("box-edit")) return;
  e.preventDefault();

  e.target.classList.toggle("box-edit");

  const theBox = e.target.closest(".box");
  const index = +theBox.dataset.index;
  const items = theBox.querySelector(".items");

  let targetIndex;
  boxes.forEach((box, i, _) => {
    if (box.number === index) {
      targetIndex = i;
    }
  });

  const editContainer = document.createElement("div");
  editContainer.classList.add("edit__container");

  const newTextarea = document.createElement("textarea");
  newTextarea.textContent = boxes[targetIndex].items.join("\n");
  newTextarea.classList.add("textarea", "edit-textarea", "ss02");
  items.firstElementChild.style.display = "none";
  items.style.margin = "1rem";

  const btnSet = document.createElement("button");
  btnSet.textContent = "ثبت";
  btnSet.classList.add("btn", "btn-submit", "raise", "btn-edit-set");

  editContainer.append(newTextarea, btnSet);

  items.append(editContainer);
});

// Saving editted boxes
boxesContainer.addEventListener("click", function (e) {
  if (!e.target.classList.contains("btn-edit-set")) return;

  e.preventDefault();

  const theBox = e.target.closest(".box");
  const index = +theBox.dataset.index;
  const items = theBox.querySelector(".items");
  const editContainer = theBox.querySelector(".edit__container");

  let targetIndex;
  boxes.forEach((box, i, _) => {
    if (box.number === index) {
      targetIndex = i;
    }
  });

  theBox.querySelector(".btn-edit").classList.toggle("box-edit");

  const updatedItems = (boxes[targetIndex].items = itemsOrganizer(
    e.target.previousElementSibling.value
  ));

  items.firstElementChild.innerHTML = itemsHTML(updatedItems);

  editContainer.remove();
  items.firstElementChild.style.display = "initial";
  items.style.marginTop = "2rem";
  items.style.marginRight = "3.5rem";

  localsaveBoxes();
});

boxesContainer.addEventListener("click", function (e) {
  if (!e.target.classList.contains("btn-remove")) return;
  e.preventDefault();

  if (confirm("آیا از حذف باکس اطمینان دارید؟")) {
    const theBox = e.target.closest(".box");
    const index = +theBox.dataset.index;

    boxes.forEach((box, i, arr) => {
      if (box.number === index) {
        arr.splice(i, 1);
      }
    });

    boxesUpdate();
    localsaveBoxes();
    boxNumContainer.firstElementChild.textContent =
      boxes.at(-1)?.number + 1 || 1;
  }
});

btnSearch.addEventListener("click", function (e) {
  e.preventDefault();
  const filteredBoxes = [];
  boxes.forEach((box, i, arr) => {
    if (box.items.some((item) => item.includes(inputSearch.value)))
      filteredBoxes.push(arr[i]);
  });
  boxesContainer.innerHTML = "";
  filteredBoxes.forEach((box) => {
    let items = box.items
      .map((item) => {
        return `<li>${item}</li>`;
      })
      .join("\n");
    boxesContainer.insertAdjacentHTML(
      "beforeend",
      boxMaker(box.number, box.title, items)
    );
    boxNumEditable(autoNum);
  });
});

inputSearch.addEventListener("input", function (e) {
  const filteredBoxes = [];
  boxes.forEach((box, i, arr) => {
    if (box.items.some((item) => item.includes(inputSearch.value)))
      filteredBoxes.push(arr[i]);
  });
  boxesContainer.innerHTML = "";
  filteredBoxes.forEach((box) => {
    let items = box.items
      .map((item) => {
        return `<li>${item}</li>`;
      })
      .join("\n");
    boxesContainer.insertAdjacentHTML(
      "beforeend",
      boxMaker(box.number, box.title, items)
    );
    boxNumEditable(autoNum);
  });
});

btnDellall.addEventListener("click", function (e) {
  e.preventDefault();
  if (confirm("آیا از حذف همه اطمینان دارید؟")) {
    boxes.length = 0;
    boxesUpdate();
    localsaveBoxes();
    boxNumContainer.firstElementChild.textContent = 1;
  }
});

btnPrint.addEventListener("click", function (e) {
  e.preventDefault();
  const previousAppend = document.querySelector(".finalPrint");
  if (previousAppend) previousAppend.remove();
  const finalPrint = boxesContainer.cloneNode();
  const printClone = boxesContainer.cloneNode(true);
  printClone.classList.add("printClone");
  finalPrint.classList.add("finalPrint");
  [...printClone.children].forEach((_, i, arr) => {
    const div = document.createElement("div");
    if (i % 2 === 0) {
      if (!arr[i + 1]) {
        div.append(arr[i]);
        finalPrint.append(div);
      } else {
        div.append(arr[i], arr[i + 1]);
        finalPrint.append(div);
      }
    }
  });
  boxesContainer.insertAdjacentElement("afterend", finalPrint);

  [...finalPrint.children].forEach((div) => {
    [...div.children].forEach((box, i, arr) => {
      if (arr[0] && arr[1]) {
        const h1 = parseFloat(getComputedStyle(arr[0])?.height);
        const h2 = parseFloat(getComputedStyle(arr[1])?.height);
        if (h1 && h2 && h1 !== h2) {
          if (h1 > h2) {
            arr[1].style.height = h1 - 39 + "px";
          } else {
            arr[0].style.height = h2 - 39 + "px";
          }
        }
      }
    });
  });

  window.print();
});

userIcon.addEventListener("mouseenter", function (e) {
  userIconText.style.opacity = "100";
});
userIcon.addEventListener("mouseleave", function (e) {
  userIconText.style.opacity = "0";
});

// Start of Auth Window Scrips

const modal = document.querySelector(".auth-window");
const overlay = document.querySelector(".overlay");
const btnCloseModal = document.querySelector(".close-modal");
const signinForm = document.querySelector(".signin");
const signupForm = document.querySelector(".signup");
const formsContainer = document.querySelector(".forms");
const signinFormBtn = document.querySelector(".signin-form-btn");
const signupFormBtn = document.querySelector(".signup-form-btn");

const openModal = function () {
  modal.classList.remove("hidden");
  overlay.classList.remove("hidden");
  document.body.style.height = "100vh";
  document.body.style.overflow = "hidden";
};
userIcon.addEventListener("click", openModal);

const closeModal = function () {
  modal.classList.add("hidden");
  overlay.classList.add("hidden");
  document.body.style.height = "auto";
  document.body.style.overflow = "auto";
};
btnCloseModal.addEventListener("click", closeModal);
overlay.addEventListener("click", closeModal);

document.addEventListener("keydown", function (e) {
  if (e.key === "Escape" && !modal.classList.contains("hidden")) {
    closeModal();
  }
});

signinForm.addEventListener("submit", function (e) {
  e.preventDefault();
  const email = this.email.value;
  const password = this.password.value;
});
signupForm.addEventListener("submit", function (e) {
  e.preventDefault();
  const email = this.email.value;
  const password = this.password.value;
  const passRepeat = this.passRepeat.value;
});

signupFormBtn.addEventListener("click", function (e) {
  e.preventDefault();
  signupFormBtn.classList.add("auth-selected");
  signinFormBtn.classList.remove("auth-selected");
  signupForm.style.transform = "translateX(0)";
  signinForm.style.transform = "translateX(100%)";
  formsContainer.style.height = "400px";
});

signinFormBtn.addEventListener("click", function (e) {
  e.preventDefault();
  signupFormBtn.classList.remove("auth-selected");
  signinFormBtn.classList.add("auth-selected");
  signupForm.style.transform = "translateX(-100%)";
  signinForm.style.transform = "translateX(0)";
  formsContainer.style.height = "310px";
});

// Toggle Pass Option
formsContainer.addEventListener("click", function (e) {
  if (!e.target.classList.contains("curser-area")) return;

  const input = e.target.previousElementSibling;
  const iconSlash = e.target.nextElementSibling;
  const iconSolid = iconSlash.nextElementSibling;

  const type = input.getAttribute("type") === "password" ? "text" : "password";

  input.setAttribute("type", type);

  if (!iconSlash.classList.contains("hidden")) {
    iconSlash.classList.add("hidden");
    iconSolid.classList.remove("hidden");
  } else {
    iconSlash.classList.remove("hidden");
    iconSolid.classList.add("hidden");
  }
});

checkbox.addEventListener("click", function (e) {
  const btnEditNum = boxNumEditable();
  if (this.checked !== true) {
    autoNum = false;
    localStorage.setItem("autoNum", JSON.stringify(autoNum));
    btnEditNum.forEach((btn) => {
      btn.style.display = "flex";
    });
  } else {
    autoNum = true;
    localStorage.setItem("autoNum", JSON.stringify(autoNum));
    btnEditNum.forEach((btn) => {
      btn.style.display = "none";
    });
    boxNumContainer.firstElementChild.textContent =
      boxes.at(-1)?.number + 1 || 1;
    boxesUpdate();
  }
});

const mainContainer = document.querySelector(".main-container");

mainContainer.addEventListener("click", function (e) {
  if (!e.target.classList.contains("box-num-edit-img")) return;

  const boxNum = e.target.previousElementSibling.textContent;

  const div = document.createElement("div");
  div.classList.add("box-naming-input-container");
  const input = document.createElement("input");
  input.type = "text";
  input.id = "box-naming-input";
  input.classList.add("search-input", "box-naming-input", "ss02");
  input.value = boxNum;
  const btn = document.createElement("button");
  btn.textContent = "ثبت";
  btn.classList.add("search-btn", "box-naming-btn");
  div.append(input, btn);

  e.target.previousElementSibling.replaceWith(div);

  e.target.style.display = "none";

  input.focus();
  input.select();
});

mainContainer.addEventListener("click", function (e) {
  if (!e.target.classList.contains("box-naming-btn")) return;
  e.preventDefault();
  const title = e.target.previousElementSibling.value;
  const span = document.createElement("span");
  const index = +e.target.closest(".box")?.dataset?.index;

  span.classList.add("title");
  span.textContent = title;
  e.target.parentElement.replaceWith(span);
  boxNumEditable(autoNum);

  if (index) {
    let targetIndex;
    boxes.forEach((box, i, _) => {
      if (box.number === index) {
        targetIndex = i;
      }
    });

    boxes[targetIndex].title = title;
    localsaveBoxes();
  }

  // boxesUpdate();
});
